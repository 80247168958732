import React from "react"
import MainHeading from '@typo/MainHeading'
import Text from '@typo/Text'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Header from "@menus/header"

const PrivacyPolicy = styled.div`
  background-color: white;
  max-width:1200px;
  margin:auto;
  padding-top:80px;
  @media (max-width: 1000px) {
    max-width:90%;
  }
`


const Terms = (props) => {

  const { t } = useTranslation()


  return (
    <>
    <Header location={props.location} alwaysShow={true}/> 
        <PrivacyPolicy>
        <MainHeading center>{t('terms-of-service.sectionHeading')}</MainHeading>
        <Text center>{t('terms-of-service.text-1')}</Text>
        <Text>{t('terms-of-service.heading-1')}</Text>

        <h2>{t('terms-of-service.heading-2')}</h2>
        <Text>{t('terms-of-service.text-2')}</Text>

        <h2>{t('terms-of-service.heading-3')}</h2>
        <Text>{t('terms-of-service.text-3')}
          
        </Text>

        <h2>{t('terms-of-service.heading-4')}</h2>
        <Text>{t('terms-of-service.text-4')}

        </Text>

        <h2>{t('terms-of-service.heading-5')}</h2>
        <Text>{t('terms-of-service.text-5')}</Text>

        <h2>{t('terms-of-service.heading-6')}</h2>
        <Text>{t('terms-of-service.text-6')}</Text>

        <h2>{t('terms-of-service.heading-7')}</h2>
        <Text>{t('terms-of-service.text-7')}</Text>

        <h2>{t('terms-of-service.heading-8')}</h2>
        <Text>{t('terms-of-service.text-8')}</Text>
        <Text>{t('terms-of-service.text-9')}</Text>
        <Text>{t('terms-of-service.text-10')}</Text>


        <h2>{t('terms-of-service.heading-9')}</h2>
        <h3>{t('terms-of-service.heading-10')}</h3>
        <Text>{t('terms-of-service.text-11')}</Text>
        <Text>{t('terms-of-service.text-12')}</Text>
        <h3>{t('terms-of-service.heading-11')}</h3>
        <Text>{t('terms-of-service.text-13')}</Text>
        <Text>{t('terms-of-service.text-14')}</Text>

        <ol>
          <li>{t('terms-of-service.listItem-1')}</li>
          <li>{t('terms-of-service.listItem-2')}</li>
          <li>{t('terms-of-service.listItem-3')}</li>
        </ol>
        <Text>{t('terms-of-service.text-15')}</Text>

        <h3>{t('terms-of-service.heading-12')}</h3>
        <h2>{t('terms-of-service.heading-13')}</h2>
        <Text>{t('terms-of-service.text-16')}</Text>
        <h3>{t('terms-of-service.heading-14')}</h3>
        <Text>{t('terms-of-service.text-17')}</Text>
        <Text>{t('terms-of-service.text-18')} </Text>
        <Text>{t('terms-of-service.text-19')}</Text>
        <Text>{t('terms-of-service.text-20')}</Text>
        <h3>{t('terms-of-service.heading-15')}</h3>
        <Text>{t('terms-of-service.text-21')}</Text>
        <Text>{t('terms-of-service.text-22')}</Text>
        <h3>{t('terms-of-service.heading-16')}</h3>
        <Text>{t('terms-of-service.text-23')}</Text>
        <Text>{t('terms-of-service.text-24')}</Text>


        <h3>{t('terms-of-service.heading-17')}</h3>
        <Text>{t('terms-of-service.text-25')}
        
        </Text>

        <h3>{t('terms-of-service.heading-18')}</h3>
        <Text>{t('terms-of-service.text-26')}</Text>
        
        <h2>{t('terms-of-service.heading-19')}</h2>
        <Text>{t('terms-of-service.text-27')}</Text>
        <h2>{t('terms-of-service.heading-20')}</h2>
        <Text>{t('terms-of-service.text-28')} </Text>
        
        <Text>{t('terms-of-service.text-29')}</Text>
        <ol>
          <li>{t('terms-of-service.listItem-4')}</li>
          <li>{t('terms-of-service.listItem-5')}</li>
          <li>{t('terms-of-service.listItem-6')}</li>
          <li>{t('terms-of-service.listItem-7')}</li>
          <li>{t('terms-of-service.listItem-8')}</li>
        </ol>
        <Text>{t('terms-of-service.text-30')}</Text>
        <h2>{t('terms-of-service.heading-21')}</h2>
        <Text>{t('terms-of-service.text-31')}</Text>
        <Text>{t('terms-of-service.text-32')}</Text>
        <Text>{t('terms-of-service.text-33')}</Text>
           
        <h2>{t('terms-of-service.heading-22')}</h2>
        <Text>{t('terms-of-service.text-34')}</Text>
        <Text>{t('terms-of-service.text-35')}</Text>
        <h2>{t('terms-of-service.heading-23')}</h2>
        <Text>{t('terms-of-service.text-36')}</Text>
        <Text>{t('terms-of-service.text-37')}</Text>
        <Text>{t('terms-of-service.text-38')}</Text>
        <Text>{t('terms-of-service.text-39')}</Text> 
        <Text>{t('terms-of-service.text-40')}</Text>
        <Text>{t('terms-of-service.text-41')}</Text>
        <Text>{t('terms-of-service.text-42')}</Text>
        <Text>{t('terms-of-service.text-43')}</Text>
        <Text>{t('terms-of-service.text-44')}</Text>
        <h2>{t('terms-of-service.heading-24')}</h2>
        <Text>{t('terms-of-service.text-45')}</Text>

        <ol>
          <li>{t('terms-of-service.listItem-9')}</li>
          <li>{t('terms-of-service.listItem-10')}</li>
          <li>{t('terms-of-service.listItem-11')}</li>
          <li>{t('terms-of-service.listItem-12')}</li>
          <li>{t('terms-of-service.listItem-13')}</li>
          <li>{t('terms-of-service.listItem-14')}</li>
          <li>{t('terms-of-service.listItem-15')}</li>
          <li>{t('terms-of-service.listItem-16')}</li>
          <li>{t('terms-of-service.listItem-17')}</li>
          <li>{t('terms-of-service.listItem-18')}</li>
          <li>{t('terms-of-service.listItem-19')}</li>
          <li>{t('terms-of-service.listItem-20')}</li>
          <li>{t('terms-of-service.listItem-21')}</li>
          <li>{t('terms-of-service.listItem-22')}</li>
          <li>{t('terms-of-service.listItem-23')}</li>
          <li>{t('terms-of-service.listItem-24')}</li>
        </ol>
        <Text>{t('terms-of-service.text-46')}</Text> 

        <h2>{t('terms-of-service.heading-25')}</h2>
        <Text>{t('terms-of-service.text-47')}</Text>

        <h2>{t('terms-of-service.heading-26')}</h2>
        <Text>{t('terms-of-service.text-48')}</Text>
        
        <h2>{t('terms-of-service.heading-27')}</h2>
        <Text>{t('terms-of-service.text-49')}</Text>
        <Text>{t('terms-of-service.text-50')}</Text>
        <h2>{t('terms-of-service.heading-28')}</h2>
        <Text>{t('terms-of-service.text-51')}</Text> 
        
        <h2>{t('terms-of-service.heading-29')}</h2>
        <Text>{t('terms-of-service.text-52')}</Text> 
        
        <h2>{t('terms-of-service.heading-30')}</h2>
        <h3>{t('terms-of-service.heading-31')}</h3>
        <Text>{t('terms-of-service.text-53')}
          
        </Text>
        <h3>{t('terms-of-service.heading-32')}</h3>
        <Text>{t('terms-of-service.text-54')}
          
        </Text>

        <h3>{t('terms-of-service.heading-33')}</h3>
        <Text>{t('terms-of-service.text-55')}
          
        </Text>
        
        <h3>{t('terms-of-service.heading-34')}</h3>
        <Text>{t('terms-of-service.text-56')}
          
        </Text>
        
        <h3>{t('terms-of-service.heading-35')}</h3>
        <Text>{t('terms-of-service.text-57')}
          
        </Text>
        
        <h3>{t('terms-of-service.heading-36')}</h3>
        <Text>{t('terms-of-service.text-58')}
          
        </Text>

        <h3>{t('terms-of-service.heading-37')}</h3>
        <Text>{t('terms-of-service.text-59')}
        
        </Text>

        <h3>{t('terms-of-service.heading-38')}</h3>
        <Text>{t('terms-of-service.text-60')}
        
        </Text>

        <h2>{t('terms-of-service.heading-39')}</h2>
        <h3>{t('terms-of-service.heading-40')}</h3>
        <Text>{t('terms-of-service.text-61')}
          
        </Text>
        <h3>{t('terms-of-service.heading-41')}</h3>
        <Text>{t('terms-of-service.text-62')}
        
        </Text>

        <h3>{t('terms-of-service.heading-42')}</h3>
        <Text>{t('terms-of-service.text-63')}
        
        </Text>

        <h3>{t('terms-of-service.heading-43')}</h3>
        <Text>{t('terms-of-service.text-64')}
        
        </Text>

        <h3>{t('terms-of-service.heading-44')}</h3>
        <Text>{t('terms-of-service.text-65')}
          
        </Text>

        <h2>{t('terms-of-service.heading-45')}</h2>
        <Text>{t('terms-of-service.text-66')}</Text>
      </PrivacyPolicy>
      </>
  )
}
export default Terms